:root {
    --spanish-gray: hsl(0, 0%, 60%);
    --sonic-silver: hsl(0, 0%, 47%);
    --cultured: hsl(0, 0%, 93%);
    --white: hsl(0, 100%, 100%);
    --blue: #4e94b2;
    --dark--blue: #020a5d;
    --color-light: rgba(132, 139, 200, 0.18);
    --sky-blue: rgb(225, 236, 237);


    --box-shadow: 0 1rem 2rem var(--color-light);
    --box-shadow-green: 10px 10px 50px hsla(114, 98%, 20%, 0.5);
    --box-shadow-black: 1px 2px 3px hsla(0, 0%, 0%, 0.5);

    --border-radius-md: 10px;
    --border-radius-sm: 5px;
}




.about {
    margin-top: 20px;
    background: var(--cultured);
    height: 90vh;
    margin-bottom: -47px;
}

.about-container {
    /* display: flex; */
    position: absolute;
    height: 70vh;
    width: 75vw;
    top: 270px;
    right: 200px;
    background: var(--white);
    border-radius: 20px;
}

.about-container-left {
    width: 550px;
    padding: 30px;
    margin-top: 50px;
    margin-right: 200px;
    margin-left: 60px;
    margin-bottom: 50px;
    background: var(--white);
    box-shadow: var(--box-shadow-black);
    border-radius: 40px 0;
}

.about-container-left .about-container-item-left {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.about-container-left .input-container {
    display: flex;
    height: 50px;
    margin-top: 20px;
}

.item-desc {
    margin-top: -30px;
}

.input-field-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    background: var(--sky-blue);
    opacity: 0.4;
}

.input-field-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    color: var(--white);
    background: var(--blue);
    opacity: 0.4;
}


.about-container-right {
    position: absolute;
    top: 0px;
    right: 0px;
    background: var(--blue);
    width: 250px;
    height: 543px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 5;
}

.about-container-item-right {
    position: absolute;
    top: 120px;
    right: 150px;
    width: 300px;
    height: 300px;
    border-radius: 0 30px;
    background: var(--cultured);
}

.about-container-item-right .item-right-image {
    width: 180px;
    margin-left: 60px;
    margin-top: 40px;
}

.about-container-right .item-right-2 {
    position: absolute;
    width: 140px;
    height: 50px;
    top: 250px;
    right: 430px;
    border-radius: 0 20px;
    background: var(--sky-blue);
    opacity: 0.5;
}

.about-container-right .item-right-2 p {
    margin-left: 25px;
    margin-top: 10px;
}


/* HOME MEDIA QUERY FOR SCREEN 1349 */
@media (max-width: 1349px) {

    .about-container {
        /* left: 60px; */
    }

    .about-container-left {
        width: 500px;
        margin-right: 220px;
    }

    .about-container-right {
        width: 200px;
        margin-right: -80px;
    }

    .about-container-item-right {
        right: 100px;
        width: 280px;
        height: 280px;
    }

    .about-container-item-right .item-right-image {
        width: 160px;
    }

    .about-container-right .item-right-2 {
        width: 140px;
        height: 50px;
        right: 350px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 1250 */
@media (max-width: 1250px) {

    .about-container-item-right {
        top: 140px;
        right: 80px;
        width: 250px;
        height: 250px;
    }

    .about-container-item-right .item-right-image {
        width: 140px;
    }

    .about-container-right .item-right-2 {
        top: 240px;
        right: 290px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 1060*/
@media (max-width: 1170px) {

    .about-container-left {
        width: 400px;
    }

    .input-field-1 {
        font-size: 14px;
        opacity: 0.7;
    }
    
    .input-field-2 {
        font-size: 13px;
        opacity: 0.7;
    }

}


/* CHEMICAL MEDIA QUERY FOR SCREEN 1130*/
@media (max-width: 1130px) {

    .about-container {
        height: 77vh;
    }
}


/* HOME MEDIA QUERY FOR SCREEN 1060*/
@media (max-width: 1060px) {

    .about {
        height: 130vh;
    }

    .about-container {
        right: 90px;
        height: 110vh;
        width: 80vw;
        overflow: hidden;
    }

    .about-container-left {
        position: absolute;
        right: -170px;
        height: 50vh;
        width: 630px;
    }

    .input-field-1 {
        font-size: 18px;
        opacity: 0.6;
    }
    
    .input-field-2 {
        font-size: 17px;
        opacity: 0.6;
    }

    .about-container-right {
        position: absolute;
        width: 848px;
        height: 200px;
        right: 80px;
        top: 650px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 20px;
    }

    .about-container-item-right {
        top: -100px;
        right: 270px;
        width: 250px;
        height: 250px;
        z-index: 5;
    }

    .about-container-right .item-right-2 {
        top: -150px;
        right: 320px;
        z-index: 5;
        transform: rotate(90deg);
    }

}

/* HOME MEDIA QUERY FOR SCREEN 930*/
@media (max-width: 930px) {
    
    .about-container-left {
        right: -170px;
        width: 560px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 845*/
@media (max-width: 845px) {
    
    .about-container-left {
        width: 480px;
    }

    .input-field-1 {
        font-size: 16px;
        opacity: 0.6;
    }
    
    .input-field-2 {
        font-size: 15px;
        opacity: 0.6;
    }

    .about-container-item-right {
        top: -100px;
        right: 220px;
        width: 220px;
        height: 220px;
    }

    .about-container-right .item-right-2 {
        right: 260px;
    }

    .about-container-item-right .item-right-image {
        width: 110px;
        margin-left: 55px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 750*/
@media (max-width: 750px) {

    .about-container {
        right: 70px;
    }
    
    .about-container-left {
        width: 420px;
    }

    .input-field-1 {
        font-size: 14px;
    }
    
    .input-field-2 {
        font-size: 13px;
    }

    .about-container-item-right {
        right: 190px;
    }

    .about-container-right .item-right-2 {
        right: 230px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 670*/
@media (max-width: 670px) {

    .about-container {
        right: 60px;
    }
    
    .about-container-left {
        width: 360px;
    }

    .about-container-left .item-title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .about-container-left .input-container {
        margin-top: -8px;
    }

    .input-field-1 {
        font-size: 12px;
    }
    
    .input-field-2 {
        font-size: 11px;
    }

    .about-container-item-right {
        right: 160px;
    }

    .about-container-right .item-right-2 {
        right: 200px;
    }

}


/* HOME MEDIA QUERY FOR SCREEN 600*/
@media (max-width: 600px) {

    .about-container {
        right: 50px;
    }
    
    .about-container-left {
        width: 320px;
    }

    .input-field-1 {
        font-size: 11px;
        opacity: 1.0;
    }
    
    .input-field-2 {
        font-size: 10px;
        opacity: 1.0;
    }

    .about-container-item-right {
        right: 130px;
    }

    .about-container-right .item-right-2 {
        right: 170px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 600*/
@media (max-width: 600px) {

    .about-container {
        right: 40px;
        height: 115vh;
    }
    
    .about-container-left {
        width: 300px;
        height: 420px;
    }

    .about-container-left .item-title {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .input-field-1 {
        font-size: 10px;
        opacity: 1.0;
    }
    
    .input-field-2 {
        font-size: 10px;
        opacity: 1.0;
    }

    .about-container-right {
        top: 690px;
    }

    .about-container-item-right {
        right: 120px;
        width: 200px;
        height: 200px;
    }

    .about-container-right .item-right-2 {
        right: 150px;
    }

    .about-container-item-right .item-right-image {
        width: 100px;
        margin-left: 50px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 530*/
@media (max-width: 530px) {
    
    .about-container-left {
        width: 270px;
    }

    .about-container-left .item-title {
        font-size: 16px;
        text-align: center;
    }

    .about-container-left .item-desc {
        text-align: center;
    }

    .input-field-1 {
        font-size: 9px;
        opacity: 1.0;
    }
    
    .input-field-2 {
        font-size: 9px;
        opacity: 1.0;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 480*/
@media (max-width: 480px) {

    .about-container-left {
        width: 240px;
    }

    .about-container-left .item-title {
        font-size: 15px;
        margin-bottom: 8px;
        text-align: center;
    }

    .about-container-left .item-desc {
        text-align: center;
    }

    .input-field-1 {
        font-size: 11px;
        text-align: center;
        opacity: 1.0;
    }
    
    .input-field-2 {
        font-size: 11px;
        text-align: center;
        opacity: 1.0;
    }

    .about-container-item-right {
        right: 90px;
        width: 200px;
        height: 200px;
    }

    .about-container-right .item-right-2 {
        right: 120px;
    }

    .about-container-item-right .item-right-image {
        width: 90px;
        margin-left: 55px;
    }

}


/* HOME MEDIA QUERY FOR SCREEN 440*/
@media (max-width: 440px) {

    .about-container-left {
        width: 210px;
    }

    .about-container-left .item-title {
        font-size: 13px;
        margin-bottom: 6px;
        text-align: center;
    }

    .about-container-left .item-desc {
        text-align: center;
    }

    .about-container-item-right {
        right: 80px;
        width: 200px;
        height: 200px;
    }

    .about-container-right .item-right-2 {
        right: 110px;
    }

    .about-container-item-right .item-right-image {
        width: 90px;
        margin-left: 55px;
    }

}


/* HOME MEDIA QUERY FOR SCREEN 412*/
@media (max-width: 412px) {

    .about {
        height: 150vh;
    }

    .about-container {
        right: 30px;
        height: 125vh;
    }

    .about-container-left {
        width: 180px;
        height: 440px;
        margin-right: 210px;
    }

    .about-container-left .item-title {
        font-size: 13px;
        margin-bottom: 6px;
        text-align: center;
    }

    .about-container-left .item-desc {
        text-align: center;
    }

    .about-container-right {
        top: 766px;
    }

    .about-container-item-right {
        top: -140px;
        right: 55px;
        width: 200px;
        height: 200px;
    }

    .about-container-right .item-right-2 {
        right: 90px;
        top: -185px;
    }

    .about-container-item-right .item-right-image {
        width: 90px;
        margin-left: 55px;
    }

}

/* MEDIA QUERY FOR SCREEN 380 */
@media (max-width: 380px) {

    .about {
        height: 150vh;
    }

    .about-container {
        right: 30px;
        height: 129vh;
    }

    .about-container-left {
        width: 160px;
        height: 480px;
    }

    .about-container-right {
        top: 800px;
    }

    .about-container-item-right {
        top: -110px;
        right: 60px;
        width: 190px;
        height: 190px;
    }

    .about-container-right .item-right-2 {
        right: 80px;
        top: -175px;
    }
  
}


/* MEDIA QUERY FOR SCREEN 360 */
@media (max-width: 360px) {

    .about {
        height: 155vh;
    }

    .about-container {
        right: 30px;
        height: 138vh;
    }

    .about-container-left {
        width: 160px;
        height: 480px;
    }

    .about-container-right {
        top: 790px;
    }

    .about-container-item-right {
        top: -110px;
        right: 45px;
        width: 190px;
        height: 190px;
    }

    .about-container-right .item-right-2 {
        right: 70px;
        top: -165px;
    }
 
}

/* MEDIA QUERY FOR SCREEN 360 */
@media (max-width: 350px) {

    .about {
        height: 150vh;
    }

    .about-container {
        right: 30px;
        height: 129vh;
    }

    .about-container-left {
        width: 160px;
        height: 480px;
    }

    .about-container-right {
        top: 800px;
    }

    .about-container-item-right {
        top: -110px;
        right: 45px;
        width: 190px;
        height: 190px;
    }

    .about-container-right .item-right-2 {
        right: 70px;
        top: -175px;
    }
 
}