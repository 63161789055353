:root {

    /**
     * colors
     */
  
    --spanish-gray: hsl(0, 0%, 60%);
    --sonic-silver: hsl(0, 0%, 47%);
    --eerie-black: hsl(0, 0%, 13%);
    --salmon-pink: hsl(353, 100%, 78%);
    --sandy-brown: hsl(29, 90%, 65%);
    --bittersweet: hsl(0, 100%, 70%);
    --ocean-green: hsl(152, 51%, 52%);
    --davys-gray: hsl(0, 0%, 33%);
    --cultured: hsl(0, 0%, 93%);
    --white: hsl(0, 100%, 100%);
    --onyx: hsl(0, 0%, 27%);
    --green: green;
    --primary: #00adb5;
    --black: black;
    --blue: #4e94b2;
    --dark--blue: #020a5d;
    --red: #e60707;
    --color-info-light: #dce1eb;
    --color-light: rgba(132, 139, 200, 0.18);
    --dark-beach:  #9d9e8e;
    --darker-beach: #787970;
    --light-beach: #c9c8b4;
    --lemon-green: #8bc34a;
    --black: black;
    --orange: rgb(253 140 28);
    --dark-red: rgb(140, 6, 6);
    --orange-two: rgb(249 115 22);
    --brown: #914900;
    --dark-brown: #854e28;

     /**
     * Box Shadow
     */
    --box-shadow: 0 1rem 2rem var(--color-light);
    --box-shadow-green: 10px 10px 50px hsla(114, 98%, 20%, 0.5);
    --box-shadow-black: 1px 2px 3px hsla(0, 0%, 0%, 0.5);
  
    /**
     * typography
     */
  
    --fs-1: 1.563rem;
    --fs-2: 1.375rem;
    --fs-3: 1.25rem;
    --fs-4: 1.125rem;
    --fs-5: 1rem;
    --fs-6: 0.938rem;
    --fs-7: 0.875rem;
    --fs-8: 0.813rem;
    --fs-9: 0.75rem;
    --fs-10: 0.688rem;
    --fs-11: 0.625rem;
  
    --weight-300: 300;
    --weight-400: 400;
    --weight-500: 500;
    --weight-600: 600;
    --weight-700: 700;
    --weight-800: 800;
  
    /**
     * border-radius
     */
  
    --card-border-radius: 0.5rem;
    --container-border-radius: 0.8rem;
  
    /**
     * transition 
     */
  
    --transition-timing-sm: all 300ms ease-in-out;
    --transition-timing-md: all 400ms ease-in-out;
    --transition-timing-lg: all 500ms ease-in-out;
    --transition-timing-ease: all 200ms ease;
  
  }


* {
    margin: 0;
    padding: 0;
}

.product {
    margin-top: 1rem;
    background: var(--white);
}

.image-container img {
    width: 100%;
    /* object-fit: cover; */
}

.image-overlay {
    display: flex;
    width: 100%;
    height: 25rem;
    margin-top: -25.1rem;
    opacity: 0.8;
    align-items: center;
    justify-content: center;
    background: var(--eerie-black);
}

.image-overlay p {
    font-size: clamp(1rem, -0.25rem + 2.5vw, 2.75rem);
    color: var(--white);
}

.platinum-product-container {
    margin: 0.5rem min(4rem, 8%);
}

.product-headline {
    display: flex;
    padding: 0.6rem ;
    font-size: clamp(1rem, -0.25rem + 2.5vw, 1.75rem);
    color: var(--white);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background: var(--dark-red);
}

.product-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

 .product-card {
    display: flex;
    gap: 0.8rem;
    width: 25rem;
    flex-grow: 1;
    margin: 1rem 0rem;
    padding: 0.5rem;
    background: var(--white);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow-black);
}

.product-card-left img {
    max-width: 100%px;
}


.product-card-right {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    position: relative;
}

.product-card-right p {
    font-size: 0.9rem;
}

.product-card-right h2 {
    font-size: 1.2rem;
}

.product-card-right h3 {
    font-size: 0.9rem;
}

.horizontal-line {
    height: 0.1rem;
    background: var(--cultured);
}

.product-rating-and-btn {
    display: flex;
    justify-content: space-between;
}

.product-rating-and-btn .product-rating {
    color: var(--orange);
}

.product-rating-and-btn button {
    outline: none;
    border: none;
    width: 7rem;
    padding: 0.7rem;
    border-radius: 0.2rem;
    color: var(--white);
    background: var(--brown);
    cursor: pointer;
}

.product-rating-and-btn button:hover {
    background: var(--dark-brown);
}

.accessory {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    position: absolute;
    right: -1rem;
    opacity: 0;
    top: -0.4rem;
    background: var(--white);
    padding: 0.2rem;
    border: 2px solid var(--cultured);
    border-radius: 0.4rem;
    transition: all 0.3s ease-in-out;
}

.product-card:hover .accessory {
    right: -0.1rem;
    opacity: 1;
}

.accessory-item {
    border: 1px solid var(--cultured);
    border-radius: 0.2rem;
    padding: 0.1rem;
    height: 1.1rem;
}
