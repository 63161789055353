:root {
    --spanish-gray: hsl(0, 0%, 60%);
    --sonic-silver: hsl(0, 0%, 47%);
    --cultured: hsl(0, 0%, 97%);

        /**
     * border-radius
     */
  
     --border-radius-md: 10px;
     --border-radius-sm: 5px;

          /**
     * Box Shadow
     */
    --box-shadow: 0 1rem 2rem var(--color-light);
    --box-shadow-green: 10px 10px 50px hsla(114, 98%, 20%, 0.5);
    --box-shadow-black: 1px 2px 3px hsla(0, 0%, 0%, 0.5);
  
}



.more-infor {
    position: absolute;
    top: 0;
    width: 100%;
    height: 415vh;
    background: transparent;
}

.more-info-container {
    position: absolute;
    top: 1350px;
    left: 150px;
    width: 80%;
    height: 48%;
    text-align: center;
    box-shadow: var(--box-shadow-black);
    background: var(--cultured);
    border-radius: var(--border-radius-md);
    opacity: none;
    /* z-index: 5; */
}

.more-infor .more-info-container .button {
    position: absolute;
    left: 1240px;
    cursor: pointer;
}