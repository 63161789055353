:root {

    /**
     * colors
     */
  
    --spanish-gray: hsl(0, 0%, 60%);
    --sonic-silver: hsl(0, 0%, 47%);
    --eerie-black: hsl(0, 0%, 13%);
    --salmon-pink: hsl(353, 100%, 78%);
    --sandy-brown: hsl(29, 90%, 65%);
    --bittersweet: hsl(0, 100%, 70%);
    --ocean-green: hsl(152, 51%, 52%);
    --davys-gray: hsl(0, 0%, 33%);
    --cultured: hsl(0, 0%, 93%);
    --white: hsl(0, 100%, 100%);
    --onyx: hsl(0, 0%, 27%);
    --green: green;
    --primary: #00adb5;
    --black: black;
    --blue: #4e94b2;
    --dark--blue: #020a5d;
    --red: #e60707;
    --color-info-light: #dce1eb;
    --color-light: rgba(132, 139, 200, 0.18);
    --dark-beach:  #9d9e8e;
    --darker-beach: #787970;
    --light-beach: #c9c8b4;
    --lemon-green: #8bc34a;
    --black: black;

     /**
     * Box Shadow
     */
    --box-shadow: 0 1rem 2rem var(--color-light);
    --box-shadow-green: 10px 10px 50px hsla(114, 98%, 20%, 0.5);
    --box-shadow-black: 1px 2px 3px hsla(0, 0%, 0%, 0.5);
  
    /**
     * typography
     */
  
    --fs-1: 1.563rem;
    --fs-2: 1.375rem;
    --fs-3: 1.25rem;
    --fs-4: 1.125rem;
    --fs-5: 1rem;
    --fs-6: 0.938rem;
    --fs-7: 0.875rem;
    --fs-8: 0.813rem;
    --fs-9: 0.75rem;
    --fs-10: 0.688rem;
    --fs-11: 0.625rem;
  
    --weight-300: 300;
    --weight-400: 400;
    --weight-500: 500;
    --weight-600: 600;
    --weight-700: 700;
    --weight-800: 800;
  
    /**
     * border-radius
     */
  
    --card-border-radius: 0.5rem;
    --container-border-radius: 0.8rem;
  
    /**
     * transition 
     */
  
    --transition-timing-sm: all 300ms ease-in-out;
    --transition-timing-md: all 400ms ease-in-out;
    --transition-timing-lg: all 500ms ease-in-out;
    --transition-timing-ease: all 200ms ease;
  
  }


* {
    margin: 0;
    padding: 0;
}

.service {
    background: var(--white);
}

.production-service-section {
    margin: 1.1rem min(6em, 8%);
    padding-left:  min(0.1em, 8%);
    padding-right:  min(0.1em, 8%);
    padding-bottom:  min(2em, 8%);
    background: var(--dark-beach);
}

.production-service-section img {
    display: grid;
    justify-self: center;
    max-width: 100%;
    /* height: auto; */
    /* aspect-ratio: 10/5; aspect-ratio and object fit is use mostly to deal with multiple images to have consistence sizes */ 
    object-fit: cover;
}

.production-title {
    text-align: center;
    margin-top: -380px;
    color: var(--white);
}

h1 {
    font-size: clamp(1rem, -0.2rem + 3vw, 3rem);
}

.production-card {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    width: 15rem;
    flex-grow: 1;
    padding: min(1em, 7%);
    background: var(--white);
    transition: all 0.3s ease;
}

.production-card-icon {
    padding: 0.5rem;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    transition: all 0.5s ease;
}

.production-card:hover .production-card-icon {
    transform: scale(1.5);
    color: var(--green);
}

.production-card p {
    font-size: clamp(0.8rem, calc(1vw + 0.3rem), 0.2rem);
    line-height: 1.8;
}

.production-card-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6rem;
    gap: 1rem;
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
    /* justify-content: center; */
    
}

.production-card-arrow.one {
    margin-top: 40px;
}
.production-card-arrow.two {
    margin-top: 65px;
}
.production-card-arrow.three {
    margin-top: 20px;
}
.production-card-arrow.four {
    margin-top: 90px;
}













/* PAINTING SERVICE SECTION */

.painting-service-section {
    margin: 2rem min(6em, 8%);
    /* background: var(--cultured); */
}

.painting-section-title {
    text-align: center;
    padding: min(2em, 5%);
    margin-bottom: 2rem;
}

.painting-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: 15rem;
    text-align: center;
    padding: 0.5rem;
    background: var(--white);
    flex-grow: 1;
    border-radius: var(--card-border-radius);
    box-shadow: 1px 1px 3px hsla(0, 0%, 0%, 0.5);
    transition: all 0.5s ease;
}

.painting-card:hover {
    transform: scale(1.02);
    box-shadow: var(--box-shadow);
}

.painting-card.two {
    background: var(--darker-beach);
    color: var(--white);
}

.painting-card h2 {
    font-size: clamp(0.5rem, calc(1vw + 1rem), 1rem);
}

.painting-card p {
    font-size: clamp(0.8rem, calc(1vw + 0.3rem), 0.2rem);
}

.painting-card button {
    width: 100px;
    height: 30px;
    font-size: 11px;
    margin-top: 1rem;
    border: none;
    color: var(--white);
    background: var(--lemon-green);
}


.painting-card-icon {
    width: 3rem;
    height: 3rem;
    display: flex;
    border-radius: 50%;
    margin-top: -2rem;
    background: var(--white);
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow-black);
}

.painting-section-card-container {
   display: flex;
   flex-wrap: wrap;
   gap: 2rem;
}

.painting-service-caption {
    text-align: center;
    padding: min(2em, 5%);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.painting-service-caption-button {
    width: 12rem;
    font-size: 16px;
    padding: 1rem;
    border: none;
    margin: auto;
    margin-top: 1rem;
    border-radius: var(--border-radius-md);
    color: var(--white);
    cursor: pointer;
    background: var(--dark-beach);
    transition: all 0.3s ease;
}

.painting-service-caption-button:hover{
    background: var(--darker-beach);
    transform: scale(1.1);
}











/* CHEMICAL SALES SERVICE SECTION */

.chemical-sale-service-section {
    margin: 0rem min(6em, 8%);
    padding-top: 5rem;
    padding-bottom: 1rem;
    background: var(--darker-beach);
}

.chemical-sale-service-section img {
    display: none;
    justify-self: center;
    max-width: 100%;
    max-height: 100%;
    /* height: auto; */
    /* aspect-ratio: 10/5; aspect-ratio and object fit is use mostly to deal with multiple images to have consistence sizes */ 
    /* object-fit: cover; */
}

.chemical-sale-service-container {
    margin-top: -3rem;
    padding-left: 2rem;
    padding-right: 2rem;

}

.chemical-sale-service-container h1 {
        text-align: center;
        color: var(--white);
}

.chemical-sale-card {
    display: flex;
    gap: 0.8rem;
    width: 17rem;
    flex-grow: 1;
    color: var(--white);
    padding: 1rem;
    background: var(--black);
    transition: all 0.5s ease;
}

.chemical-sale-card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.8rem;
    padding: 1rem;
    border-radius: 50%;
    box-shadow: 1px 1px 15px 1px hsla(0, 100%, 100%, 1);
    transition: all 0.5s ease;
}

.chemical-sale-card-text h2 {
    margin-bottom: 1rem;
    font-size: clamp(0.5rem, calc(1vw + 1rem), 1rem);
}

.chemical-sale-card-text p {
    font-size: clamp(0.9rem, calc(1vw + 0.3rem), 0.2rem);
}


.chemical-sale-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
}

.chemical-sale-card:hover .chemical-sale-card-icon{
    transform: scale(3);
    box-shadow: 1px 1px 15px 1px hsla(0, 100%, 100%, 0.3);
}