
:root {

    /**
     * colors
     */
  
    --spanish-gray: hsl(0, 0%, 60%);
    --sonic-silver: hsl(0, 0%, 47%);
    --eerie-black: hsl(0, 0%, 13%);
    --salmon-pink: hsl(353, 100%, 78%);
    --sandy-brown: hsl(29, 90%, 65%);
    --bittersweet: hsl(0, 100%, 70%);
    --ocean-green: hsl(152, 51%, 52%);
    --davys-gray: hsl(0, 0%, 33%);
    --cultured: hsl(0, 0%, 93%);
    --white: hsl(0, 100%, 100%);
    --onyx: hsl(0, 0%, 27%);
    --green: green;
    --primary: #00adb5;
    --black: black;
    --blue: #4e94b2;
    --dark--blue: #020a5d;
    --red: #e60707;
    --color-info-light: #dce1eb;
    --color-light: rgba(132, 139, 200, 0.18);

     /**
     * Box Shadow
     */
    --box-shadow: 0 1rem 2rem var(--color-light);
    --box-shadow-green: 10px 10px 50px hsla(114, 98%, 20%, 0.5);
    --box-shadow-black: 1px 2px 3px hsla(0, 0%, 0%, 0.5);
  
    /**
     * typography
     */
  
    --fs-1: 1.563rem;
    --fs-2: 1.375rem;
    --fs-3: 1.25rem;
    --fs-4: 1.125rem;
    --fs-5: 1rem;
    --fs-6: 0.938rem;
    --fs-7: 0.875rem;
    --fs-8: 0.813rem;
    --fs-9: 0.75rem;
    --fs-10: 0.688rem;
    --fs-11: 0.625rem;
  
    --weight-300: 300;
    --weight-400: 400;
    --weight-500: 500;
    --weight-600: 600;
    --weight-700: 700;
    --weight-800: 800;
  
    /**
     * border-radius
     */
  
    --border-radius-md: 10px;
    --border-radius-sm: 5px;
  
    /**
     * transition 
     */
  
    --transition-timing-sm: all 300ms ease-in-out;
    --transition-timing-md: all 400ms ease-in-out;
    --transition-timing-lg: all 500ms ease-in-out;
    --transition-timing-ease: all 200ms ease;
  
  }


.home {
    margin-top: 20px;
    width: 100%;
}

.slide {
    position: relative;
}

.slide img {
    width: 100%;
    height: 800px;
}

.image-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    top: 270px;
    left: 200px;
    width: 440px;
    height: 150px;
    padding: 30px;
    border-radius: 20px;
}

.image-text.right {
    left: 850px;
    top: 279px;
}

/* .image-text:hover {
    transform: scale(1.2);
    box-shadow: var(--box-shadow-green);
} */

.image-text .image-text-heading {
    font-size: 30px;
    font-weight: var(--weight-700);
    color: var(--black);
    /* width: 480px; */
    color: var(--green);
}

.image-text .image-text-heading.left {
    /* width: 280px; */
}

.image-text .image-text-heading.right {
    /* width: 400px; */
}

.image-text-container .image-text-desc {

}


.middle-feature-text-container {
    display: flex;
    gap: 40px;
    width: 70%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 70px;
}

.middle-feature-text-container .middle-feature-text {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
}

.middle-feature-text p {
    text-align: center;
}


/* Middle Section */
.middle {
    display: flex;
    flex-direction: column;
    gap: 40px;
}


.middle-container {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 71%;
    height: 400px;
    box-shadow: var(--box-shadow-black);
    margin-bottom: 60px;
}

.middle-container-left {

}

.middle-container-left img {
    width: 600px;
    height: 400px;
}

.middle-container-right {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 350px;
    gap: 60px;
    margin: 0 10px;
}

.middle-container button {
    width: 200px;
    height: 40px;
    background: var(--blue);
    border: none;
    margin-top: -7px;
    border-radius: var(--border-radius-md);
    cursor: pointer;
    color: var(--white);
    font-size: var(--fs-5);
    font-weight: var(--weight-600);
}

.middle-container.house button {
    padding: 8px;
    margin-top: -25px;
}

.middle-container button:active {
    background: #3b768a;
}

/* Grid Section */
.grid {
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: 0 74px;
    margin-bottom: 60px;
}

.grid .grid-item img {
    width: 251px;
}

.grid-item {
    width: 251px;
    height: 358px;
    position: relative;
    box-shadow: var(--box-shadow-black);
    transition: all 300ms ease-in-out;
}

.grid .grid-item .grid-desc {
    position: absolute;
    top: 230px;
    background: var(--white);
    height: 120px;
    width: 251px;
}

.grid .grid-item .grid-desc p {
    padding: 10px 10px;
    font-size: 18px;
}

.grid-item:hover {
    transform: scale(1.05);
    box-shadow: var(--box-shadow);
}

/* Middle Container After Grid */
.middle-container.pallet {
    margin: 0 220px;
    background: var(--white);
    box-shadow: var(--box-shadow-black);
}

.middle-container-left.pallet img {
    width: 400px;
    height: 380px;
}

.middle-container-right.pallet {
    /* background: var(--cultured); */
    /* box-shadow: var(--box-shadow-black); */
    margin-left: 1px;
    padding: 5px 20px;
}


/* HOME MEDIA QUERY FOR SCREEN 1350 */
@media (max-width: 1350px) {
    .image-text {
        left: 100px;
    }

    .image-text.right {
        left: 700px;
    }

    .middle-container-left img {
        width: 450px;
    }

    .middle-container-right {
        height: 350px;
    }

    .middle-container button {
        padding: 10px;
        margin-bottom: 20px;
        margin-top: -20px;
    }

    .grid {
        gap: 30px;
        margin: 0 65px;
    }

    .grid-item {
        width: 215px;
    }

    .grid .grid-item .grid-desc {
        width: 215px;
    }

    .grid .grid-item img {
        width: 215px;
        height: 225px;
    }

    /* Middle Container After Grid */
    .middle-container.pallet {
        margin: 0 189px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 1227 */
@media (max-width: 1227px) {

    .image-text {
        left: 3px;
    }

    .image-text.right {
        left: 480px;
    }

    .slide .image-text-heading {
        font-size: 25px;
    }

    .middle-feature-text-container {
        width: 90%;
        margin: 50px ;
    }

    .middle-container {
        width: 90%;
        height: 300px;
    }


    .middle-container-left img {
        width: 400px;
        height: 300px;
    }

    .middle-container-right h2 {
        font-size: 16px;
        margin-top: 40px;
    }

    .middle-container-right p {
        font-size: 16px;
    }

    .middle-container-right {
        gap: 50px;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .grid-item {
        width: 400px;
    }

    .grid .grid-item img {
        width: 400px;
        height: 357px;
    }

    .grid .grid-item .grid-desc {
        top: 289px;
        height: 70px;
        width: 400px;
    }


    /* Middle Container After Grid */
    .middle-container.pallet {
        margin: 0 60px;
        width: 88%;
    }

    .middle-container-left.pallet img {
        width: 300px;
        height: 280px;
    }
    
    .middle-container-right.pallet {
        padding: 5px 20px;
        height: 290px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 950 */
@media (max-width: 950px) {

    .image-text {
        left: 200px;
        top: 120px;
    }

    .image-text.right {
        left: 200px;
        top: 420px;
    }

    .middle-feature-text h2 {
        font-size: 18px;
    }

    .middle-feature-text p {
        font-size: 14px;
    }

    .middle-feature-text-container {
        margin-left: 30px;
    }

    .middle-feature-text-container .middle-feature-text-icon {
        width: 25px;
    }

    .middle-container {
        height: 250px;
    }

    .middle-container-left img {
        width: 350px;
        height: 250px;
    }

    .middle-container-right {
        height: 250px;
        gap: 10px;
    }

    .middle-container-right h2 {
        font-size: 16px;
    }

    .middle-container-right p {
        font-size: 14px;
    }

    .middle-container-right button {
        font-size: 14px;
        margin-top: 10px;
        width: 180px;
        height: 35px;
    }

    .middle-container.house .middle-container-right button {
        margin-top: 8px;
    }

    .grid {
        margin: auto;
    }

    .grid-item {
        width: 380px;
    }

    .grid .grid-item img {
        width: 380px;
        height: 357px;
    }

    .grid .grid-item .grid-desc {
        top: 279px;
        height: 80px;
        width: 380px;
    }

    .grid .grid-item .grid-desc p {
        font-size: 16px;
    }

    /* Middle Container After Grid */
    .middle-container.pallet {
        margin: 0 50px;
        width: 88%;
        height: 250px;
     }
    
    .middle-container-left.pallet img {
        width: 250px;
        height: 250px;
    }
        
     .middle-container-right.pallet {
        padding: 5px 20px;
        height: 240px;
        gap: 20px;
    }

}

/* MEDIA QUERY FOR SCREEN 850 */
@media (max-width: 850px) {

    .image-text {
        left: 160px;
    }

    .image-text.right {
        left: 160px;
    }

    .middle-container-left img {
        width: 320px;
        height: 250px;
    }

    .middle-container-right {
        height: 245px;
        line-height: 1.2;
        overflow: auto;
    }

    .middle-container-right button {
        width: 150px;
        font-size: 14px;
    }

    .middle-container.house .middle-container-right button {
        margin-top: -3px;
    }

    .middle-container.house .middle-container-right p {
        line-height: 1.2;
    }

    .grid {
        margin: 0 40px;
    }

    .grid-item {
        width: 332px;
    }

    .grid .grid-item img {
        width: 332px;
        height: 357px;
    }

    .grid .grid-item .grid-desc {
        top: 279px;
        height: 80px;
        width: 332px;
    }

    .grid .grid-item .grid-desc p {
        font-size: 16px;
    }

    /* Middle Container After Grid */       
    .middle-container-right.pallet button {
        margin-top: -10px;
    }

    .middle-container-right.pallet p {
        line-height: 1.2;
    }

}

/* MEDIA QUERY FOR SCREEN 750 */
@media (max-width: 750px) {

    .image-text {
        left: 130px;
    }

    .image-text.right {
        left: 130px;
    }

    .middle-container {
        height: 320px;
        gap: 5px;
    }

    .middle-container-left img {
        width: 320px;
        height: 300px;
        margin-left: 10px;
    }

    .middle-container-right {
        height: 300px;
        gap: 30px;
        overflow: auto;
    }

    .grid {
        margin: 0 40px;
    }

    .grid-item {
        width: 300px;
    }

    .grid .grid-item img {
        width: 300px;
        height: 357px;
    }

    .grid .grid-item .grid-desc {
        top: 279px;
        height: 80px;
        width: 300px;
    }

    .grid .grid-item .grid-desc p {
        font-size: 16px;
    }

    /* Middle Container After Grid  */
    .middle-container.pallet {
        height: 320px;
    }

    .middle-container-left.pallet img {
        width: 300px;
        height: 300px;
    }

    .middle-container-right.pallet {
        height: 300px;
        gap: 30px;
    }
    
    .middle-container-right.pallet p {
        line-height: 1.1;
    }
}

/* MEDIA QUERY FOR SCREEN 650 */
@media (max-width: 650px) {

    .image-text {
        left: 80px;
    }

    .image-text.right {
        left: 80px;
    }

    .middle-container {
        height: 320px;
        gap: 5px;
    }

    .middle-container-left img {
        width: 300px;
        height: 300px;
        margin-left: 10px;
    }

    .middle-container-right {
        height: 300px;
        gap: 30px;
        overflow: auto;
    }

    .grid {
        margin: 0 40px;
    }

    .grid-item {
        width: 250px;
    }

    .grid .grid-item img {
        width: 250px;
        height: 357px;
    }

    .grid .grid-item .grid-desc {
        top: 279px;
        height: 80px;
        width: 250px;
    }

    .grid .grid-item .grid-desc p {
        font-size: 16px;
    }
}



/* MEDIA QUERY FOR SCREEN 575 */
@media (max-width: 575px) {

    .image-text {
        left: 90px;
        width: 280px;
    }

    .image-text h2 {
        margin-bottom: -10px;
        margin-top: -15px;
        margin-left: -25px;
        font-size: 20px;
    }

    .image-text.right {
        left: 90px;
        top: 450px;
        width: 280px;
    }

    .image-text.right h2 {
        font-size: 20px;
    }

    .middle-feature-text-container {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
    }

    .middle-feature-text h2 {
        font-size: 18px;
    }

    .middle-feature-text p {
        font-size: 14px;
    }

    .middle-feature-text-container .middle-feature-text-icon {
        width: 25px;
    }

    .middle-container {
        display: flex;
        flex-direction: column;
        width: 320px;
        height: 630px;
    }

    .middle-container-left img {
        width: 305px;
        height: 300px;
        margin-left: 10px;
    }

    .middle-container-right {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-items: center;
        width: 300px;
        height: 300px;
        padding: 5px;
        gap: 20px;
        margin: -5px 0;
        padding: -10px 5px;
    }

    .middle-container-right button {
        font-size: 14px;
        margin: auto;
        width: 180px;
        height: 35px;
    }

    .grid {
        display: grid;
        width: 320px;
        margin: auto;
        grid-template-columns: 1fr;
    }

    .grid-item {
        width: 317px;
    }

    .grid .grid-item img {
        width: 317px;
        height: 357px;
    }

    .grid .grid-item .grid-desc {
        top: 289px;
        height: 70px;
        width: 317px;
    }

        /* Middle Container After Grid */
        .middle-container.pallet {
            display: flex;
            flex-direction: column;
           margin: auto;
            width: 320px;
            height: 650px;
         }
        
        .middle-container-left.pallet img {
            width: 300px;
            height: 300px;
        }
            
         .middle-container-right.pallet {
            padding: 5px 20px;
            height: 310px;
            width: 260px;
        }

}



/* MEDIA QUERY FOR SCREEN 412 */
@media (max-width: 412px) {

    .image-text {
        left: 20px;
        width: 280px;
    }

    .image-text h2 {
        margin-bottom: -10px;
        margin-top: -15px;
        margin-left: -25px;
        font-size: 20px;
    }

    .image-text.right {
        left: 20px;
        top: 450px;
        width: 280px;
    }

    .image-text.right h2 {
        font-size: 20px;
    }

    .middle-feature-text-container {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
    }

    .middle-feature-text h2 {
        font-size: 18px;
    }

    .middle-feature-text p {
        font-size: 14px;
    }

    .middle-feature-text-container .middle-feature-text-icon {
        width: 25px;
    }

    .middle-container {
        display: flex;
        flex-direction: column;
        width: 320px;
        height: 630px;
    }

    .middle-container-left img {
        width: 305px;
        height: 300px;
        margin-left: 10px;
    }

    .middle-container-right {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-items: center;
        width: 300px;
        height: 300px;
        padding: 5px;
        gap: 20px;
        margin: -5px 0;
        padding: -10px 5px;
    }

    .middle-container-right button {
        font-size: 14px;
        margin: auto;
        width: 180px;
        height: 35px;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr;
    }

    .grid-item {
        width: 317px;
    }

    .grid .grid-item img {
        width: 317px;
        height: 357px;
    }

    .grid .grid-item .grid-desc {
        top: 289px;
        height: 70px;
        width: 317px;
    }

        /* Middle Container After Grid */
        .middle-container.pallet {
            display: flex;
            flex-direction: column;
            margin: 0 40px;
            width: 320px;
            height: 650px;
         }
        
        .middle-container-left.pallet img {
            width: 300px;
            height: 300px;
        }
            
         .middle-container-right.pallet {
            padding: 5px 20px;
            height: 310px;
            width: 260px;
        }

}

/* MEDIA QUERY FOR SCREEN 380 */
@media (max-width: 380px) {

    .middle-container.pallet {
        margin: 0 20px;
    }
}

/* MEDIA QUERY FOR SCREEN 360 */
@media (max-width: 360px) {

    .middle-container.pallet {
        margin: 0 8px;
    }
}


