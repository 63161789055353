:root {

    /**
     * colors
     */
  
    --spanish-gray: hsl(0, 0%, 60%);
    --sonic-silver: hsl(0, 0%, 47%);
    --eerie-black: hsl(0, 0%, 13%);
    --salmon-pink: hsl(353, 100%, 78%);
    --sandy-brown: hsl(29, 90%, 65%);
    --bittersweet: hsl(0, 100%, 70%);
    --ocean-green: hsl(152, 51%, 52%);
    --davys-gray: hsl(0, 0%, 33%);
    --cultured: hsl(0, 0%, 93%);
    --white: hsl(0, 100%, 100%);
    --onyx: hsl(0, 0%, 27%);
    --box-shadow-green: 10px 10px 50px hsla(114, 98%, 20%, 0.5);
    --box-shadow-black: 0 0 10px hsla(0, 0%, 0%, 0.5);
    --green: green;
    --primary: #00adb5;
    --light-gray: rgb(199, 199, 199);
    --blue: #4e94b2;
    --dark--blue: #020a5d;
    --red: #e60707;
  
    /**
     * typography
     */
  
    --fs-1: 1.563rem;
    --fs-2: 1.375rem;
    --fs-3: 1.25rem;
    --fs-4: 1.125rem;
    --fs-5: 1rem;
    --fs-6: 0.938rem;
    --fs-7: 0.875rem;
    --fs-8: 0.813rem;
    --fs-9: 0.75rem;
    --fs-10: 0.688rem;
    --fs-11: 0.625rem;
  
    --weight-300: 300;
    --weight-400: 400;
    --weight-500: 500;
    --weight-600: 600;
    --weight-700: 700;
  
    /**
     * border-radius
     */
  
    --border-radius-md: 10px;
    --border-radius-sm: 5px;
  
    /**
     * transition 
     */
  
    --transition-timing-sm: all 300ms ease-in-out;
    --transition-timing-md: all 400ms ease-in-out;
    --transition-timing-lg: all 500ms ease-in-out;
  
  }



.navbar {
    width: 100%;
    height: 200px;
    box-shadow: var(--box-shadow-black);
}

.navbar h2 {
    color: var(--white);
}

.nav-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--blue);
    height: 80px;
}

.nav-menu {
    margin-top: -20px;
}

.nav-menu-mobile {
    display: none;
}

/* NavBar Menu Icon Grid */
.mobile-menu-icon-open {
    display: none;
}

.mobile-menu-icon-close {
    display: none;
}

.nav-top h2 {
    margin-left: 20px;
}

.navbar .contacts {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 10px;
}

.navbar .contacts div {
    font-weight: var(--weight-600);
    color: var(--white);
}

.contacts div {
    display: flex;
    gap: 10px;
    align-items: center;
}

.navbar .contacts b {
    font-size: 20px;
}

.navbar-menu-item-list {
    display: flex;
    gap: 20px;
    width: 64%;
    height: 80px;
    align-items: center;
    margin-left:  400px;
    margin-top: 60px;
    position: relative;
    transition: width 0.5s ease, height 0.5s ease;
}

.navbar-menu-item.home {
    display: flex;
    gap: 1px;
    align-items: center;
    margin-bottom: 25px;
    /* background: var(--green); */
    /* color: var(--white); */
}

.navbar-menu-item.home p {
    margin-bottom: -10px;
}

.navbar-menu-item {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Times New Roman', Times, serif;
    color: black;
    padding: 8px;
    width: 130px;
    border-radius: 5px;
    transition: width 0.5s ease;
}


.navbar-menu-item-list hr {
    position: absolute;
    /* top: 0px; */
    bottom: 0px;
    width: 130px;
    height: 4px;
    outline: none;
    border: none;
    border-radius: 5rem;
    background: limegreen;
    transition: width all 300s linear;
}







/* MEDIA QUERY FOR SCREEN 1300 */
@media (max-width: 1350px) {

    .navbar-menu-item-list {
        margin: 50px 260px;
    }
}

/* MEDIA QUERY FOR SCREEN 1000 */
@media (max-width: 1227px) {

    .navbar-menu-item-list {
        margin: 50px 120px;
    }

}


/* MEDIA QUERY FOR SCREEN 950 */
@media (max-width: 950px) {

    .navbar-menu-item-list {
        margin: 50px 60px;
        gap: 35px;
    }

    .navbar-menu-item-list .home-icon {
        width: 30px;
    }

    .navbar-menu-item {
        width: 100px;
        font-size: 18px;
    }

    .navbar-menu-item.home {
        font-size: 18px;
    }
}

/* MEDIA QUERY FOR SCREEN 800 */
@media (max-width: 800px) {

    .navbar-menu-item-list {
        margin: 50px 30px;
        gap: 20px;
    }
}


/* MEDIA QUERY FOR SCREEN 650 */
@media (max-width: 650px) {

    .navbar-menu-item-list {
        margin: 50px 20px;
        gap: 35px;
    }

    .navbar-menu-item-list .home-icon {
        width: 25px;
    }

    .navbar-menu-item {
        width: 70px;
        font-size: 14px;
    }

    .navbar-menu-item.home {
        font-size: 14px;
    }

}

/* MEDIA QUERY FOR SCREEN 693*/
@media (max-width: 693px) {

    .nav-top .contacts {
        display: none;
    }

    .nav-top h2 {
        margin-left: 130px;
    }

    .navbar-menu-item-list {
        margin: 50px 20px;
        gap: 18px;
    }

    .navbar-menu-item {
        width: 85px;
    }

}


/* MEDIA QUERY FOR SCREEN 595 (MOBILE)*/
@media (max-width: 595px) {

    .nav-top h2 {
        margin-left: 110px;
    }

    .nav-menu-mobile {
        display: block;
    }

    .nav-menu-desktop {
        display: none;
    }

    .mobile-menu-icon-open {
        width: 25px;
        height: 25px;
        margin: 24px 4px;
        display: block;
        cursor: pointer;
    }
    .mobile-menu-icon-close {
        display: block;
        border: 1px solid rgb(192, 191, 191);
        width: 25px;
        height: 25px;
        padding: 2px;
        border-radius: var(--border-radius-sm);
    }

    .mobile-menu-item-list .mobile-menu-icon-close {
        position: absolute;
        right: 5px;
        cursor: pointer;
    }

    .nav-top .contacts {
        display: none;
    }

    .mobile-menu-item-list {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 35px;
        align-items: flex-start;
        width: 350px;
        height: 60vh;
        top: 80px;
        padding-left: 15px;
        background: var(--white);
        z-index: 10;
    }

    .mobile-menu-item.home {
        display: flex;
        gap: 2px;
        align-items: center;
    }

    .mobile-menu-item.home p {
        margin-top: 5px;
    }

    .mobile-menu-item {
        font-size: 16px;
        font-weight: 500;
        font-family: 'Times New Roman', Times, serif;
        color: black;
        padding: 10px;
        width: 85px;
        border-radius: 5px;
    }

    .mobile-menu-item:hover {
        background: var(--green);
        color: var(--white);
    }

}

/* HOME MEDIA QUERY FOR SCREEN 530*/
@media (max-width: 530px) {

    .nav-top h2 {
        margin-left: 70px;
    }
}


/* MEDIA QUERY FOR SCREEN 412 */
@media (max-width: 412px) {

    .nav-top h2 {
        font-size: 18px;
    }
}


/* MEDIA QUERY FOR SCREEN 380 */
@media (max-width: 380px) {

    .nav-top h2 {
        font-size: 16px;
    }
}

/* MEDIA QUERY FOR SCREEN 360 */
@media (max-width: 360px) {

    .mobile-menu-item-list {
        z-index: 10;
    }
}

