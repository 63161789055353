:root {
    --cultured: hsl(0, 0%, 93%);

        /**
     * border-radius
     */
  
     --border-radius-md: 10px;
     --border-radius-sm: 5px;
}



.contact {
    display: flex;
    gap: 30px;
    margin: 40px 300px;
}

.left-container img {
    border-radius: 10px;
}

.right-container {
    display: flex;
    flex-direction: column;
    gap: 230px;
    padding: 20px;
    width: 280px;
    background: #fff;
    border: 1px solid var(--cultured);
    text-align: center;
    border-radius: var(--border-radius-md);
    position: relative;
}


/* START OF BORDER ANIMATIONS */
@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

.right-container::after, .right-container::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: conic-gradient(from var(--angle), #ff4545, #00ff99, #006aff, #ff0095, #ff4545);
    /* background-image: conic-gradient(from var(--angle), transparent 70%, blue); */
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: -1;
    padding: 3px;
    border-radius: var(--border-radius-md);
    animation: 15s spin linear infinite;
}

.right-container::before {
    filter: blur(0.5rem);
    opacity: 0.1;
}

@keyframes spin {
    from {
        --angle: 0deg;
    }
    to {
        --angle: 360deg;
    }
}
/* END OF BORDER ANIMATIONS */



.right-container .top-h2 {
    margin-top: 10px;
}

.middle {
    display:  flex;
    flex-direction: column;
    gap: 10px;
}




/* CONTACT MEDIA QUERY FOR SCREEN 1290*/
@media (max-width: 1290px) {

    .contact {
        margin: 40px 250px;
    }

    .left-container img {
        width: 500px;
    }

    .right-container {
        gap: 160px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 1124*/
@media (max-width: 1124px) {

    .contact {
        margin: 40px 200px;
    }

    .left-container img {
        width: 400px;
    }

    .right-container {
        gap: 85px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 985*/
@media (max-width: 985px) {

    .contact {
        margin: 40px 130px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 920*/
@media (max-width: 920px) {

    .contact {
        margin: 40px 80px;
    }

}


/* HOME MEDIA QUERY FOR SCREEN 870*/
@media (max-width: 870px) {

    .contact {
        margin: 40px 80px;
        gap: 20px;
    }

    .left-container img {
        width: 320px;
    }

    .right-container {
        gap: 30px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 770*/
@media (max-width: 775px) {

    .contact {
        margin: 40px 40px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 735*/
@media (max-width: 735px) {

    .contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 40px 170px;
    }

    .left-container img {
        width: 320px;
    }

    .right-container {
        gap: 30px;
    }

}


/* HOME MEDIA QUERY FOR SCREEN 615*/
@media (max-width: 615px) {

    .contact {
        margin: 40px 140px;
    }

    .right-container {
        width: 280px;
        border: 1px solid var(--cultured);
        text-align: center;
        border-radius: var(--border-radius-md);
    }

}

/* HOME MEDIA QUERY FOR SCREEN 590*/
@media (max-width: 590px) {

    .right-container {
        width: 280px;
        border: 1px solid var(--cultured);
        text-align: center;
        border-radius: var(--border-radius-md);
    }
}

/* HOME MEDIA QUERY FOR SCREEN 560*/
@media (max-width: 560px) {

    .contact {
        margin: 40px 90px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 490*/
@media (max-width: 490px) {

    .contact {
        gap: 50px;
        margin: 40px 60px;
    }

}

/* HOME MEDIA QUERY FOR SCREEN 430*/
@media (max-width: 430px) {

    .contact {
        gap: 50px;
        margin: 40px 30px;
    }

}


/* HOME MEDIA QUERY FOR SCREEN 360*/
@media (max-width: 412px) {
    .contact {
        gap: 50px;
    }
}

/* HOME MEDIA QUERY FOR SCREEN 360*/
@media (max-width: 360px) {
    .contact {
        gap: 50px;
    }
}

/* HOME MEDIA QUERY FOR SCREEN 375*/
@media (max-width: 375px) {

    .contact {
        gap: 50px;
        margin: 40px 10px;
    }

}