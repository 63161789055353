.coming-soon {
    position: relative;
    width: 100%;
    height: 100%;
}

.coming-soon .image-container  {
    width: 100%;
    height: 100%;
}

.coming-soon .image-container img {
    width: 100%;
    height: 900px;
}

.text-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 200px;
    top: 90px;
    left: 330px;
}

.text-container .top {
    font-size: 70px;
    font-weight: 500;
    color: #fff;
}

.text-container .middle {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-container .middle span {
    font-size: 80px;
    color: #fff;
}

.text-container .middle p {
    font-size: 30px;
    margin-top: -95px;
    margin-left: -100px;
    color: #fff;
}

.text-container .bottom {
    font-size: 55px;
    font-weight: 600;
    color: #fff;
}

/* MEDIA QUERY FOR 800 SCREEN */

@media (max-width: 900px) {

    .coming-soon {
        height: 780px;
        /* background: green; */
    }

    .coming-soon .image-container img {
        height: 780px;
    }

    .text-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 200px;
        top: 80px;
        left: 50px;
    }

    .text-container .top {
        font-size: 40px;
        font-weight: 500;
        margin-bottom: 80px;
        color: #fff;
    }

    .text-container .middle p {
        font-size: 20px;
        margin-left: 2px;
    }

    .text-container .middle span {
        font-size: 50px;
    }

    .text-container .bottom {
        font-size: 35px;
        font-weight: 600;
        margin-left: -20px;
        color: #fff;
    }

}


/* MEDIA QUERY FOR 1000 SCREEN */
@media (max-width: 1100px) {

    .coming-soon {
        height: 780px;
        /* background: green; */
    }

    .coming-soon .image-container img {
        height: 780px;
    }

    .text-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 200px;
        top: 80px;
        /* left: 170px; */
    }

    .text-container .top {
        font-size: 40px;
        font-weight: 500;
        margin-bottom: 80px;
        color: #fff;
    }

    .text-container .middle p {
        font-size: 20px;
        margin-left: 2px;
    }

    .text-container .middle span {
        font-size: 50px;
    }

    .text-container .bottom {
        font-size: 35px;
        font-weight: 600;
        margin-left: -20px;
        color: #fff;
    }

}

/* MEDIA QUERY FOR 1330 SCREEN */
@media (max-width: 1330px) {
    .text-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 200px;
        top: 80px;
        /* left: 170px; */
    }

    .text-container .top {
        font-size: 40px;
        font-weight: 500;
        margin-bottom: 80px;
        color: #fff;
    }

    .text-container .middle p {
        font-size: 20px;
        margin-left: 2px;
    }

    .text-container .middle span {
        font-size: 50px;
    }

    .text-container .bottom {
        font-size: 35px;
        font-weight: 600;
        margin-left: -20px;
        color: #fff;
    }
}

/* MEDIA QUERY FOR 400 SCREEN */
@media (max-width: 450px) {

    .text-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 130px;
        top: 80px;
        /* left: 170px; */
    }

    .text-container .top {
        font-size: 40px;
        font-weight: 500;
        margin-bottom: 80px;
        color: #fff;
    }

    .text-container .middle p {
        font-size: 20px;
        margin-left: 2px;
    }

    .text-container .middle span {
        font-size: 50px;
    }

    .text-container .bottom {
        font-size: 35px;
        font-weight: 600;
        margin-left: -20px;
        color: #fff;
    }
}

/* MEDIA QUERY FOR 600 SCREEN */
@media (max-width: 600px) {

    .text-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 120px;
        top: 80px;
        /* left: 170px; */
    }
}

/* MEDIA QUERY FOR 300 SCREEN */
@media (max-width: 350px) {

        .text-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 180px;
        top: 100px;
        /* left: 170px; */
    }

    .text-container .top {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 80px;
        color: #fff;
    }

    .text-container .middle p {
        font-size: 14px;
        margin-left: 2px;
        margin-top: -80px;
       
    }

    .text-container .middle span {
        font-size: 30px;
        line-height: 0.7;
    }

    .text-container .bottom {
        font-size: 25px;
        font-weight: 600;
        margin-left: -20px;
        color: #fff;
    }
}