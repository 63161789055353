:root {
    --cultured: hsl(0, 0%, 93%);
    --lite-black: rgb(56, 55, 55);

        /**
     * border-radius
     */
  
     --border-radius-md: 10px;
     --border-radius-sm: 5px;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.2);
    background: black;
    height: 50vh;
}

.footer-logo {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.footer-logo span {
    color: #fff;
}

.footer-logo img {
    cursor: pointer;
}

.footer-logo h1 {
    color: #0f8404;
}

.footer-menu {
    display: flex;
    align-items: center;
    gap: 30px;
    list-style: none;
    margin: 30px 0;
    font-size: 15px;
}

.footer-menu li {
    cursor: pointer;
    color: #fff;
}

.footer-social-icon {
    display: flex;
    gap: 20px;
    margin: 20px 0;
    align-items: center;
    cursor: pointer;
}

.footer-social-icon-item {
    background: #fff;
    padding: 7px;
    height: 30px;
    border-radius: 5px;
}


.footer hr {
    margin-top: 30px;
    width: 100%;
    height: 2px;
    border: none;
    background: var(--lite-black);
}

.copyright {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: -10px;
    color: #fff;
}

/* HOME MEDIA QUERY FOR SCREEN 950 */
@media (max-width: 950px) {

    .footer h1 {
        font-size: 25px;
    }

    .footer-menu {
       margin-right: 50px;
        font-size: 15px;
    }

    .footer-social-icon {
        margin-right: 30px;
    }

    .copyright {
        margin-right: 25px;
    }
}


/* HOME MEDIA QUERY FOR SCREEN 505*/
@media (max-width: 505px) {

    .footer {
        height: 70vh;
    }

    .footer h1 {
        font-size: 18px;
    }

    .footer-menu {
        display: flex;
        flex-direction: column;
       margin-right: 50px;
        font-size: 15px;
    }

    .footer-social-icon {
        margin-right: 30px;
    }

    .footer-social-icon-item {
        padding: 2px;
    }

    .copyright {
        margin-right: 25px;
        font-size: 12px;
    }

}



/* HOME MEDIA QUERY FOR SCREEN 430*/
@media (max-width: 430px) {
    .footer {
        height: 58vh;
    }

    .footer-logo {
       margin-top: -20px;
    }

    .footer h1 {
        font-size: 16px;
        margin-left: -106px;
    }

    .footer-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: -215px;
        line-height: 0.2;
    }

    .footer-social-icon {
        margin-left: -290px;
        margin-bottom: -10px;
        width: 20px;
        gap: 8px;
    }

    .copyright {
        margin-right: 25px;
        font-size: 10px;
    }

}



/* HOME MEDIA QUERY FOR SCREEN 412 */
@media (max-width: 412px) {

    .footer {
        height: 58vh;
    }

    .footer-logo {
       margin-top: -20px;
    }

    .footer h1 {
        font-size: 16px;
        margin-left: -106px;
    }

    .footer-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: -215px;
        line-height: 0.2;
    }

    .footer-social-icon {
        margin-left: -290px;
        margin-bottom: -10px;
        width: 20px;
        gap: 8px;
    }

    .copyright {
        margin-right: 25px;
        font-size: 10px;
    }

}



/* HOME MEDIA QUERY FOR SCREEN 360 */
@media (max-width: 360px) {

    .footer {
        height: 58vh;
    }

    .footer-logo {
       margin-top: -20px;
    }

    .footer h1 {
        font-size: 16px;
        margin-left: -106px;
    }

    .footer-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: -215px;
        line-height: 0.2;
    }

    .footer-social-icon {
        margin-left: -290px;
        margin-bottom: -10px;
        width: 20px;
        gap: 8px;
    }

    .copyright {
        margin-right: 25px;
        font-size: 10px;
    }

}



/* HOME MEDIA QUERY FOR SCREEN 350 */
@media (max-width: 350px) {

    .footer {
        height: 58vh;
    }

    .footer-logo {
       margin-top: -20px;
    }

    .footer h1 {
        font-size: 16px;
        margin-left: -106px;
    }

    .footer-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: -215px;
        line-height: 0.2;
    }

    .footer-social-icon {
        margin-left: -290px;
        margin-bottom: -10px;
        width: 20px;
        gap: 8px;
    }

    .copyright {
        margin-right: 25px;
        font-size: 10px;
    }

}



/* HOME MEDIA QUERY FOR SCREEN 340 */
@media (max-width: 340px) {

    .footer {
        height: 58vh;
    }

    .footer-logo {
       margin-top: -20px;
    }

    .footer h1 {
        font-size: 16px;
        margin-left: -60px;
    }

    .footer-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: -170px;
        line-height: 0.2;
    }

    .footer-social-icon {
        margin-left: -240px;
        margin-bottom: -10px;
        width: 20px;
        gap: 8px;
    }

    .copyright {
        margin-right: 25px;
        font-size: 10px;
    }

}


/* HOME MEDIA QUERY FOR SCREEN 330 */
@media (max-width: 330px) {

    .footer {
        height: 58vh;
    }

    .footer-logo {
       margin-top: -20px;
    }

    .footer h1 {
        font-size: 16px;
        margin-left: -60px;
    }

    .footer-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: -170px;
        line-height: 0.2;
    }

    .footer-social-icon {
        margin-left: -240px;
        margin-bottom: -10px;
        width: 20px;
        gap: 8px;
    }

    .copyright {
        margin-right: 25px;
        font-size: 10px;
    }

}


/* HOME MEDIA QUERY FOR SCREEN 310 */
@media (max-width: 310px) {

    .footer {
        height: 58vh;
    }

    .footer-logo {
       margin-top: -20px;
    }

    .footer h1 {
        font-size: 16px;
        margin-left: -50px;
    }

    .footer-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: -150px;
        line-height: 0.2;
    }

    .footer-social-icon {
        margin-left: -220px;
        margin-bottom: -10px;
        width: 20px;
        gap: 8px;
    }

    .copyright {
        margin-right: 25px;
        font-size: 10px;
    }

}